import { useState } from 'react';

function AddressInput() {
    const [addresses, setAddresses] = useState(['']);
    const [result, setResult] = useState(null);
    const destinations = [
        "20 W 29th Street, Broadway, New York City, NY 10001-4502",
        "157 E 53rd St, New York, NY 10022"
    ];

    const handleInputChange = (index, event) => {
        const newAddresses = addresses.map((address, addrIndex) => {
            if (index !== addrIndex) return address;
            return event.target.value;
        });

        setAddresses(newAddresses);
    };

    const renderGoogleMapsResponse = (data) => {
      if (!data) return null;

      return (
          <div>
              <h3>Response from Google Maps</h3>
              <div>
                  <strong>Destination Addresses:</strong>
                  <ul>{data.destination_addresses.map(address => <li key={address}>{address}</li>)}</ul>
              </div>
              <div>
                  <strong>Origin Addresses:</strong>
                  <ul>{data.origin_addresses.map(address => <li key={address}>{address}</li>)}</ul>
              </div>
              <div>
                  <strong>Status:</strong> {data.status}
              </div>
              <div>
                  <strong>Rows:</strong>
                  <p>
                    {data.rows.map(row => <div key={row}>{JSON.stringify(row, undefined, 2)}</div>)}
                  </p>
              </div>
          </div>
      );
  };

    const handleAddAddress = () => {
        setAddresses([...addresses, '']);
    };

    const handleRemoveAddress = index => {
        const newAddresses = addresses.filter((_, addrIndex) => index !== addrIndex);
        setAddresses(newAddresses);
    };

    const handleSubmit = async () => {
      const payload = {
          origins: addresses.join('|'), // Join multiple addresses with a pipe character
          destinations: destinations.join('|'),
          mode: "TRANSIT"
      };
  
      // Construct the query string
      const queryParams = new URLSearchParams(payload).toString();
  
      const isDevelopment = process.env.NODE_ENV === 'development';
      const address = isDevelopment 
          ? "http://localhost:8787/" 
          : "https://collaboratelatenyc-worker.dennislibre.workers.dev";
      
      const url = `${address}?${queryParams}`;
      console.log(url);
      const response = await fetch(url, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
          }
      });
  
      const data = await response.json();
      setResult(data);
      console.log(data.rows);

  };  

    return (
        <div>
            <h2>Enter Addresses</h2>
            {addresses.map((address, index) => (
                <div key={index}>
                    <input
                        type="text"
                        placeholder="Enter address"
                        value={address}
                        onChange={(e) => handleInputChange(index, e)}
                    />
                    <button onClick={() => handleRemoveAddress(index)}>Remove</button>
                </div>
            ))}
            <div>
              <button onClick={handleAddAddress}>Add Another Address</button>
            </div>
            <div>
              <button onClick={handleSubmit}>Submit Addresses</button>
            </div>
            <h2>Result</h2>
            <div>{renderGoogleMapsResponse(result)}</div>
        </div>
    );
};

export default AddressInput;
